/* Define la clase para el conjunto de partículas */
.particle-set {
  position: relative;
  width: 100%;
  height: 100%;
}

.particle-fire {
  position: absolute;
  background-color: #ef5a00;
  border-radius: 50%;
  filter: drop-shadow(0 0 10px #d43322);
  animation: particleUp 2s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}

/* Resto de tus estilos y animaciones aquí */

@keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    transform: scale(0.5);
  }
}

.active {
  color: #C40404; /* Color de texto cuando el botón está activo */
  font-weight: bold;
}
