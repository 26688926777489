.video-card {
    height: 400px;
    margin-top: 15vh; 
}

.photo {
    transform: scale(0.8);
}

.photo-center {
    text-align: center;
    transform: scale(1.1);
}