
/* ******************************** General ******************************** */

.root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}


.title {
  flex: 1;
}

.footer {
  position: fixed;
  margin-top: auto;
  bottom: 0;
  width: 100%;
  color: white;
  padding: 2vh;
  background-color: #6B0303;
  z-index: 2;
}


/* ******************************** Fire Animation ******************************** */

* {
  box-sizing: border-box;
}

body {
  background-color: #111;
}

@keyframes scaleUpDown {
  0%,
  100% {
    transform: scaleY(1) scaleX(1);
  }
  50%,
  90% {
    transform: scaleY(1.1);
  }
  75% {
    transform: scaleY(0.95);
  }
  80% {
    transform: scaleX(0.95);
  }
}

@keyframes shake {
  0%,
  100% {
    transform: skewX(0) scale(1);
  }
  50% {
    transform: skewX(5deg) scale(0.9);
  }
}

@keyframes particleUp {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    top: -100%;
    transform: scale(0.5);
  }
}

@keyframes glow {
  0%,
  100% {
    background-color: #ef5a00;
  }
  50% {
    background-color: #ff7800;
  }
}

.fire {
  position: absolute;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  background-color: transparent;
  margin-left: auto;
  margin-right: auto;
}

.fire-center {
  position: absolute;
  height: 100%;
  width: 100%;
  animation: scaleUpDown 3s ease-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  .main-fire {
    position: absolute;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(
      farthest-corner at 10px 0,
      #d43300 0%,
      #ff7800 95%
    );
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }

  .particle-fire {
    position: absolute;
    top: 60%;
    left: 45%;
    width: 10px;
    height: 10px;
    background-color: #ef5a00;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
}

.fire-right {
  height: 100%;
  width: 100%;
  position: absolute;
  animation: shake 2s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  .main-fire {
    position: absolute;
    top: 15%;
    right: -25%;
    width: 80%;
    height: 80%;
    background-color: #ff7800;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }

  .particle-fire {
    position: absolute;
    top: 45%;
    left: 50%;
    width: 15px;
    height: 15px;
    background-color: #ff7800;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 2s ease-out 0;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }
}

.fire-left {
  position: absolute;
  height: 100%;
  width: 100%;
  animation: shake 3s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  .main-fire {
    position: absolute;
    top: 15%;
    left: -20%;
    width: 80%;
    height: 80%;
    background-color: #ff7800;
    transform: scaleX(0.8) rotate(45deg);
    border-radius: 0 40% 60% 40%;
    filter: drop-shadow(0 0 10px #d43322);
  }

  .particle-fire {
    position: absolute;
    top: 10%;
    left: 20%;
    width: 10%;
    height: 10%;
    background-color: #ff7800;
    border-radius: 50%;
    filter: drop-shadow(0 0 10px #d43322);
    animation: particleUp 3s infinite ease-out 0;
    animation-fill-mode: both;
  }
}

.fire-bottom .main-fire {
  position: absolute;
  top: 30%;
  left: 20%;
  width: 75%;
  height: 75%;
  background-color: #ff7800;
  transform: scaleX(0.8) rotate(45deg);
  border-radius: 0 40% 100% 40%;
  filter: blur(10px);
  animation: glow 2s ease-out 0;
  animation-iteration-count: infinite;
  animation-fill-mode: both;
}


/* ******************************** Flames Going Up******************************** */

.circles{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.circles .fire{
    position: absolute;
    display: block;
    list-style: none;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0);
    animation: animate 30s linear infinite;
    bottom: -150px;
}

.circles .fire:nth-child(1){
    left: 25%;
    width: 80px;
    height: 80px;
    animation-duration: 12s;
}


.circles .fire:nth-child(2){
    left: 10%;
    width: 20px;
    height: 20px;

    animation-duration: 23s;
}

.circles .fire:nth-child(3){
    left: 70%;
    width: 20px;
    height: 20px;
    animation-duration: 22s;
}

.circles .fire:nth-child(4){
    left: 40%;
    width: 60px;
    height: 60px;
    animation-duration: 18s;
}

.circles .fire:nth-child(5){
    left: 65%;
    width: 20px;
    height: 20px;
    animation-duration: 11s;
}

.circles .fire:nth-child(6){
    left: 75%;
    width: 110px;
    height: 110px;
    animation-duration: 20s;
}

.circles .fire:nth-child(7){
    left: 35%;
    width: 150px;
    height: 150px;
    animation-duration: 14s;
}

.circles .fire:nth-child(8){
    left: 50%;
    width: 25px;
    height: 25px;
    animation-duration: 45s;
}

.circles .fire:nth-child(9){
    left: 20%;
    width: 15px;
    height: 15px;
    animation-duration: 35s;
}

.circles .fire:nth-child(10){
    left: 85%;
    width: 150px;
    height: 150px;
    animation-duration: 27s;
}

.flame-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 220%;
  z-index: -1;
  pointer-events: none;
  background: linear-gradient(to top, #420202, #C40404);
}


@keyframes animate {

    0%{
        transform: translateY(100vh) rotate(0deg);
        opacity: 1;
        border-radius: 0;
    }

    100%{
        transform: translateY(-100vh);
        opacity: 0;
        border-radius: 50%;
    }

}







