.link:hover svg {
    animation: pulse 1.5s infinite;
    transform-origin: center;
  }

.link:hover img {
    animation: pulse 1.5s infinite;
    transform-origin: center;
  }
  
.footer-bomberos {
    width: 50px; 
}

.footer-software {
  width: 100px;
}


@keyframes pulse {
    0%, 100% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
}
  
@media (max-width: 600px) {
  .mobile-footer {
    background-color: #333;
  }

  .footer-bomberos {
    width: 30px;
  }

  .footer-software {
    width: 50px;
  }
}
